import React from 'react';
import { connect } from 'react-redux';
import ButtonArray from './ButtonArray';
import Player from './Player';

import {
  fetchNames,
  setVideo,
  changeCurrentSamplerName,
  saveSampler,
  fetchSampler,
  deleteSampler,
} from '../store/reducers/samplerReducer';

const mainArea = {
  backgroundColor: 'azure',
};

const players = {
  display: 'flex',
  flexDirection: 'column',
  // flexWrap: 'wrap',
  alignItems: 'center',
  alignContent: 'center',
};
const playerRow = {
  backgroundColor: 'floralwhite',
  width: '90%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-evenly',
  // alignItems: 'center',
};
const player = {
  // display: 'flex',
  // flexDirection: 'row',
  // flexWrap: 'wrap',
  // justifyContent: 'center',
  // alignItems: 'center',
};
const buttons = {
  // display: 'flex',
  // flexDirection: 'column',
  // alignItems: 'center',
  alignSelf: 'center',
};
const menu = {
  // padding: '5px',
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
};
const saveMenu = {
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  padding: '40px',
};
const loadMenu = {
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  padding: '40px',
};

function sliceVideoId(link) {
  let ytLink = '';
  if (link) {
    if (link.includes('watch?v=')) {
      let start = link.indexOf('=') + 1;
      ytLink = link.slice(start, start + 11);
    } else {
      ytLink = link.slice(-11);
    }
  }
  return ytLink;
}

/**
 * COMPONENT
 */
export class SampleEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      samplerSelect: '',
      videoInput1: '',
      videoInput2: '',
      videoInput3: '',
      videoInput4: '',
    };

    this.handleChange1 = this.handleChange1.bind(this);
    this.handleChange2 = this.handleChange2.bind(this);
    this.handleChange3 = this.handleChange3.bind(this);
    this.handleChange4 = this.handleChange4.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleDelete = this.handleDelete.bind(this);

    this.handleLoad = this.handleLoad.bind(this);
  }
  handleSave() {
    this.props.handleSaveSampler(this.props.players);
    this.props.getNames();
  }
  handleDelete() {
    this.props.handleDeleteSampler(this.props.players.currentSamplerName);
    this.props.getNames();
    this.setState({ samplerSelect: '' });
    this.props.getNames();
    // console.log(object)
    //     if (this.props.players.samplerNames[0]) {
    //       this.props.handleLoadSampler(this.props.players.samplerNames[0]);
    //     }
  }
  handleLoad() {
    if (this.state.samplerSelect) {
      this.props.handleLoadSampler(this.state.samplerSelect);
      this.setState({
        samplerSelect: this.state.samplerSelect,
        videoInput1: '',
        videoInput2: '',
        videoInput3: '',
        videoInput4: '',
      });
    }
  }

  handleSelectChange = event => {
    this.setState({ samplerSelect: event.target.value });
  };
  handleChange1(event) {
    let str = event.target.value;
    let inputValue = sliceVideoId(str);
    this.setState({ videoInput1: str });
    this.props.handleSetVideo(1, inputValue);
  }
  handleChange2(event) {
    let str = event.target.value;
    this.setState({ videoInput2: str });
    let inputValue = sliceVideoId(str);
    this.props.handleSetVideo(2, inputValue);
  }
  handleChange3(event) {
    let str = event.target.value;
    this.setState({ videoInput3: str });
    let inputValue = sliceVideoId(str);
    this.props.handleSetVideo(3, inputValue);
  }
  handleChange4(event) {
    let str = event.target.value;
    this.setState({ videoInput4: str });
    let inputValue = sliceVideoId(str);
    this.props.handleSetVideo(4, inputValue);
  }
  handleNameChange(event) {
    let str = event.target.value;
    this.props.changeCurrentSamplerName(str);
  }
  componentDidMount() {
    this.props.getNames();
  }
  render() {
    if (this.props.players) {
      return (
        <div style={mainArea}>
          {/* <hr /> */}
          <br></br>
          <br></br>

          <h1 className="title">
            Sample Editor: {this.props.players.currentSamplerName}
          </h1>
          <div className="menu" style={menu}>
            {/* ---------------------------- */}
            <div className="saveMenu" style={saveMenu}>
              <h3>Save Sampler:</h3>
              <div>
                <input
                  className="input"
                  type="text"
                  name="name"
                  value={this.props.players.currentSamplerName}
                  onChange={this.handleNameChange}
                />
                <button className="button" onClick={this.handleSave}>
                  Save
                </button>
                <button className="button" onClick={this.handleDelete}>
                  Delete
                </button>
              </div>
            </div>
            {/* ---------------------------- */}

            {/* ---------------------------- */}
            <div className="loadMenu" style={loadMenu}>
              <h3>Load Sampler:</h3>
              <div>
                <div className="select">
                  <select
                    name="cars"
                    value={this.state.samplerSelect}
                    onChange={this.handleSelectChange}
                  >
                    <option value=""></option>
                    {this.props.players.samplerNames.map(el => {
                      return (
                        <option value={el} key={el}>
                          {el}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <button className="button" onClick={this.handleLoad}>
                  Load
                </button>
              </div>
            </div>
            {/* ---------------------------- */}
            <br />
          </div>
          {/* ---------------------------- */}
          <div />
          <p>Lower the video quality(720p) for a snappier playback</p>
          {/* ---------------------------- */}
          <div style={players}>
            <div className="box" style={playerRow}>
              <Player style={player} id={1} />
              <div style={buttons}>
                <ButtonArray id={1} />
                <br />
                <form>
                  <label>
                    Video Link
                    <input
                      className="input"
                      type="text"
                      name="name"
                      onChange={this.handleChange1}
                      value={this.state.videoInput1}
                    />
                  </label>
                </form>
              </div>
            </div>
            {/* ---------------------------- */}
            <div className="box" style={playerRow}>
              <Player style={player} id={2} />
              <div style={buttons}>
                <ButtonArray id={2} />
                <br />
                <form>
                  <label>
                    Video Link
                    <input
                      className="input"
                      type="text"
                      name="name"
                      onChange={this.handleChange2}
                      value={this.state.videoInput2}
                    />
                  </label>
                </form>
              </div>
            </div>
            {/* ---------------------------- */}
            <div className="box" style={playerRow}>
              <Player style={player} id={3} />
              <div style={buttons}>
                <ButtonArray id={3} />
                <br />
                <form>
                  <label>
                    Video Link
                    <input
                      className="input"
                      type="text"
                      name="name"
                      onChange={this.handleChange3}
                    />
                  </label>
                </form>
              </div>
            </div>
            {/* ---------------------------- */}
            <div className="box" style={playerRow}>
              <Player style={player} id={4} />
              <div style={buttons}>
                <ButtonArray id={4} />
                <br />
                <form>
                  <label>
                    Video Link
                    <input
                      className="input"
                      type="text"
                      name="name"
                      onChange={this.handleChange4}
                    />
                  </label>
                </form>
              </div>
              {/* ---------------------------- */}
            </div>
          </div>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
        </div>
      );
    } else {
      return (
        <div>
          <p>loading...</p>
        </div>
      );
    }
  }
}

/**
 * CONTAINER
 */
const mapState = state => {
  return {
    players: state.player,
    user: state.firebase.auth.uid,
    names: state.samplerNames,
    keyMap: state.keyMap,
  };
};

const mapDispatch = dispatch => {
  return {
    handleSaveSampler(playerState) {
      dispatch(saveSampler(playerState));
    },
    handleDeleteSampler(playerState) {
      dispatch(deleteSampler(playerState));
    },
    handleLoadSampler(samplerName) {
      dispatch(fetchSampler(samplerName));
    },
    handleSetVideo(playerId, input) {
      dispatch(setVideo(playerId, input));
    },
    changeCurrentSamplerName(input) {
      dispatch(changeCurrentSamplerName(input));
    },
    getNames: () => dispatch(fetchNames()),
  };
};

export default connect(mapState, mapDispatch)(SampleEdit);

/**
 * PROP TYPES
 */
// SampleEdit.propTypes = {
//   email: PropTypes.string
// }

//=============================================================================
//=============================================================================

// import React, { Component } from 'react';
// import Player from './Player';
// import ButtonArray from './ButtonArray';

// const player_flex_style = {
//   display: 'flex',
//   justifyContent: 'center',
// };
// const timestamp_setters_style = {
//   display: 'flex',
//   justifyContent: 'center',
// };
// const editor_row = {
//   display: 'flex',
//   flexDirection: 'row',
//   flexWrap: 'wrap',
//   justifyContent: 'center',
// };
// export default class SamplerEditor extends Component {
//   constructor(props) {
//     super(props);
//   }

//   render() {
//     return (
//       <div>
//         <h1 id={'testtest'}>This is the Editor</h1>
//         <div>
//           <button>save</button>
//         </div>
//         <div style={editor_row}>
//           <Player style={player_flex_style} />
//           <ButtonArray style={timestamp_setters_style} id={'one'} />
//         </div>
//         <div style={editor_row}>
//           <Player style={player_flex_style} />
//           <ButtonArray style={timestamp_setters_style} id={'two'} />
//         </div>
//         <div style={editor_row}>
//           <Player style={player_flex_style} />
//           <ButtonArray style={timestamp_setters_style} id={'three'} />
//         </div>
//         <div style={editor_row}>
//           <Player style={player_flex_style} />
//           <ButtonArray style={timestamp_setters_style} id={'four'} />
//         </div>
//       </div>
//     );
//   }
// }
