import authReducer from './authReducer';
import playerReducer from './samplerReducer';
import keyMap from '../keyMap';

import { combineReducers } from 'redux';
import { firebaseReducer } from 'react-redux-firebase';
import { firestoreReducer } from 'redux-firestore';

const rootReducer = combineReducers({
  player: playerReducer,
  keyMap: keyMap,
  auth: authReducer,
  firebase: firebaseReducer,
  firestore: firestoreReducer,
});

export default rootReducer;
