import { createStore, applyMiddleware, compose } from 'redux';
import { createLogger } from 'redux-logger';
import { default as thunkMiddleware } from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { reduxFirestore, getFirestore } from 'redux-firestore';
import { reactReduxFirebase, getFirebase } from 'react-redux-firebase';
import fbConfig from '../config/fbConfig';
import rootReducer from './reducers/rootReducer';

const rrfConfig = {
  userProfile: 'users',
  attachAuthIsReady: true,
  useFirestoreForProfile: true,
};

const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const createStoreWithFirebase = compose(
  applyMiddleware(
    thunkMiddleware.withExtraArgument({ getFirebase, getFirestore }),
  ),
  reduxFirestore(fbConfig),
  reactReduxFirebase(fbConfig, rrfConfig)
)(createStore);

export const store = createStoreWithFirebase(persistedReducer);
export const persistor = persistStore(store);

// export * from './reducers/userReducer';
