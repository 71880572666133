import React from 'react';
import { connect } from 'react-redux';
import { render } from 'react-dom';
import { setTime, setRate } from '../store/reducers/samplerReducer';

const key_label = {
  // display: 'flex',
  // justifyContent: 'center',
  fontWeight: 'bold',
};
const button_array = {
  display: 'flex',
  // flexDirection: 'row',
  // flexWrap: 'wrap',
};
const rateInputStyle = {
  width: '90px',
};
const inputColumn = {
  flexDirection: 'column',
  justifyContent: 'center',
};

class ButtonArray extends React.Component {
  constructor(props) {
    super(props);
    this.state = { rate1: 0.0, rate2: 0.0, rate3: 0.0, rate4: 0.0 };
    this.handleRateChange1 = this.handleRateChange1.bind(this);
    this.handleRateChange2 = this.handleRateChange2.bind(this);
    this.handleRateChange3 = this.handleRateChange3.bind(this);
    this.handleRateChange4 = this.handleRateChange4.bind(this);
  }

  handleRateChange1(event) {
    let rate = parseFloat(event.target.value);
    this.setState({ rate1: rate });
    this.props.handleSetRate(this.props.id, 'rate1', rate);
  }
  handleRateChange2(event) {
    let rate = parseFloat(event.target.value);
    this.props.handleSetRate(this.props.id, 'rate2', rate);
  }
  handleRateChange3(event) {
    let rate = parseFloat(event.target.value);
    this.props.handleSetRate(this.props.id, 'rate3', rate);
  }
  handleRateChange4(event) {
    let rate = parseFloat(event.target.value);
    this.props.handleSetRate(this.props.id, 'rate4', rate);
  }

  render() {
    let player = this.props.keyMap[this.props.id];
    let idButtonOne = player.setButtonOne;
    let idButtonTwo = player.setButtonTwo;
    let idButtonThree = player.setButtonThree;
    let idButtonFour = player.setButtonFour;

    let idRateInputOne = player.setRateOne;
    let idRateInputTwo = player.setRateTwo;
    let idRateInputThree = player.setRateThree;
    let idRateInputFour = player.setRateFour;

    return (
      <div>
        <h2>Key Map</h2>
        <div style={button_array}>
          <div id={'one'} style={inputColumn}>
            <h3 style={key_label}>{this.props.keyMap[this.props.id].keyOne}</h3>
            <button
              id={idButtonOne}
              className="button"
              variant="contained"
              color="primary"
            >
              set start time
            </button>
            <div>
              <p>rate: .01 - 2</p>
              <input
                id={idRateInputOne}
                className="input"
                type="number"
                step="0.01"
                style={rateInputStyle}
                onChange={this.handleRateChange1}
                value={this.props.players[this.props.id].rate1 || ''}
              ></input>
            </div>
          </div>
          <div id={'two'}>
            <h3 style={key_label}>{this.props.keyMap[this.props.id].keyTwo}</h3>
            <button
              id={idButtonTwo}
              className="button"
              variant="contained"
              color="primary"
            >
              set start time
            </button>
            <div>
              <p>rate: .01 - 2</p>
              <input
                id={idRateInputTwo}
                className="input"
                type="number"
                step="0.01"
                style={rateInputStyle}
                onChange={this.handleRateChange2}
                value={this.props.players[this.props.id].rate2 || ''}
              ></input>
            </div>
          </div>

          <div id={'three'}>
            <h3 style={key_label}>
              {this.props.keyMap[this.props.id].keyThree}
            </h3>
            <button
              id={idButtonThree}
              className="button"
              variant="contained"
              color="primary"
            >
              set start time
            </button>
            <div>
              <p>rate: .01 - 2</p>
              <input
                id={idRateInputThree}
                className="input"
                type="number"
                step="0.01"
                style={rateInputStyle}
                onChange={this.handleRateChange3}
                value={this.props.players[this.props.id].rate3 || ''}
              ></input>
            </div>
          </div>
          <div id={'four'}>
            <h3 style={key_label}>
              {this.props.keyMap[this.props.id].keyFour}
            </h3>
            <button
              id={idButtonFour}
              className="button"
              variant="contained"
              color="primary"
            >
              set start time
            </button>
            <div>
              <p>rate: .01 - 2</p>
              <input
                id={idRateInputFour}
                className="input"
                type="number"
                step="0.01"
                style={rateInputStyle}
                onChange={this.handleRateChange4}
                value={this.props.players[this.props.id].rate4 || ''}
              ></input>
            </div>
          </div>
        </div>
        <h3>pause: {this.props.keyMap[this.props.id].keyFive}</h3>
      </div>
    );
  }
}
const mapState = state => {
  return {
    players: state.player,
    keyMap: state.keyMap,
  };
};

const mapDispatch = dispatch => {
  return {
    handleSetTime(playerId, buttonId, time) {
      dispatch(setTime(playerId, buttonId, time));
    },
    handleSetRate(playerId, rateId, rate) {
      dispatch(setRate(playerId, rateId, rate));
    },
  };
};
export default connect(mapState, mapDispatch)(ButtonArray);
