import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { signOut } from '../../store/reducers/authReducer';

const SignedInLinks = props => {
  return (
    <div>
      <nav className="navbar" role="navigation" aria-label="main navigation">
        <div className="navbar-brand">
          <React.Fragment>
            <Link to="/sampler_editor" className="navbar-item title is-5">
              Sample Edit
            </Link>
            <Link to="/perform" className="navbar-item title is-5">
              Perform
            </Link>
            <Link to="/info" className="navbar-item title is-5">
              Info
            </Link>
          </React.Fragment>
          <a
            onClick={() => {
              props.signOut();
            }}
            className="navbar-item title is-5"
          >
            Log Out
          </a>
        </div>
      </nav>
    </div>
  );
};

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    signOut: () => dispatch(signOut()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignedInLinks);
