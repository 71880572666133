import React, { Component } from 'react';

const mainArea = {
  backgroundColor: 'azure',
  height: '1200px',
};

export default class Info extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div style={mainArea}>
        <div>
          <br></br>
          <br></br>

          <h1 className="title">Welcome To The Sandbox Sampler</h1>
          <br></br>
          <p>
            Use this sampler to quickly chop up and play with internet videos
          </p>
          <br></br>
          <p>Let me know of any bugs or enhancements you want to see!</p>
          <p>email me: rdburke2891@gmail.com</p>

          <br></br>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.youtube.com/watch?v=AmtDkz9kGDg&feature=youtu.be"
          >
            Demo Video
          </a>
          <br></br>
          <br></br>
          <p>
            If you want to help support the site as well as the art of
            my friend Megan!
          </p>
          <p>
            Then buy a t-shirt or coffee mug with this sweet drawing she made
          </p>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.teepublic.com/t-shirt/1138392-yosemite-creeps-bug-and-mannequin?store_id=99418"
          >
            Store
          </a>
        </div>
      </div>
    );
  }
}
