import React from 'react';
import { connect } from 'react-redux';
import YouTube from 'react-youtube';
import { setTime, setRate } from '../store/reducers/samplerReducer';

class Player extends React.Component {
  constructor(props) {
    super(props);
    this.handleButton = this.handleButton.bind(this);
    this.videoReady = this.videoReady.bind(this);
  }
  handleButton(player, comp) {
    return function curriedFunc(e) {
      let time = player.getCurrentTime();

      let buttonPosition = e.srcElement.id.charAt(e.srcElement.id.length - 1);

      if (buttonPosition === '1') {
        comp.props.handleSetTime(comp.props.id, 1, time);
      }
      if (buttonPosition === '2') {
        comp.props.handleSetTime(comp.props.id, 2, time);
      }
      if (buttonPosition === '3') {
        comp.props.handleSetTime(comp.props.id, 3, time);
      }
      if (buttonPosition === '4') {
        comp.props.handleSetTime(comp.props.id, 4, time);
      }
    };
  }

  handleKey(player, comp) {
    return function curriedFunc(e) {
      if (player) {
        const playerNumbers = comp.props.players[comp.props.id];
        const keys = comp.props.keyMap[comp.props.id];


        let { key } = e;
        if (key === keys.keyOne) {
          if (!playerNumbers['rate1'])
            player.setPlaybackRate(playerNumbers['rate1']);
          player.seekTo(playerNumbers['1']);
          player.playVideo();
        }
        if (key === keys.keyTwo) {
          if (playerNumbers['rate2'])
            player.setPlaybackRate(playerNumbers['rate2']);
          player.seekTo(playerNumbers['2']);
          player.playVideo();
        }
        if (key === keys.keyThree) {
          if (playerNumbers['rate3'])
            player.setPlaybackRate(playerNumbers['rate3']);
          player.seekTo(playerNumbers['3']);
          player.playVideo();
        }
        if (key === keys.keyFour) {
          if (playerNumbers['rate4'])
            player.setPlaybackRate(playerNumbers['rate4']);
          player.seekTo(playerNumbers['4']);
          player.playVideo();
        }
        if (key === keys.keyFive) {
          player.pauseVideo();
        }
      }
    };
  }

  videoReady(event) {
    // access to player in all event handlers via event.target
    let player = event.target;

    if (
      document.getElementById(this.props.keyMap[this.props.id].setButtonOne) !==
      null
    ) {
      document
        .getElementById(this.props.keyMap[this.props.id].setButtonOne)
        .addEventListener('click', this.handleButton(player, this));
      document
        .getElementById(this.props.keyMap[this.props.id].setButtonTwo)
        .addEventListener('click', this.handleButton(player, this));
      document
        .getElementById(this.props.keyMap[this.props.id].setButtonThree)
        .addEventListener('click', this.handleButton(player, this));
      document
        .getElementById(this.props.keyMap[this.props.id].setButtonFour)
        .addEventListener('click', this.handleButton(player, this));
    }
    window.addEventListener('keydown', this.handleKey(player, this));
    player.pauseVideo();
  }
  render() {
    const opts = {
      height: '390',
      width: '640',
      playerVars: {
        // https://developers.google.com/youtube/player_parameters
        autoplay: 0,
      },
    };

    let video_id = this.props.players[this.props.id].inputValue;
    if (!video_id)
      video_id = '';

    return (
      <div>
        <YouTube
        key={video_id || 'empty'}
          videoId={video_id}
          opts={opts}
          onReady={this.videoReady}
        />
      </div>
    );
  }
}
const mapState = state => {
  return {
    players: state.player,
    keyMap: state.keyMap,
  };
};

const mapDispatch = dispatch => {
  return {
    handleSetTime(playerId, buttonId, time) {
      dispatch(setTime(playerId, buttonId, time));
    },
    handleSetRate(playerId, rateId, rate) {
      dispatch(setRate(playerId, rateId, rate));
    },
  };
};

export default connect(mapState, mapDispatch)(Player);
