const initialState = require('../player/initialState');

const SET_TIME = 'SET_TIME';
const SET_RATE = 'SET_RATE';
const SET_VIDEO = 'SET_VIDEO';
const SET_NAMES = 'SET_NAMES';
const CHANGE_CURRENT_SET_NAME = 'CHANGE_CURRENT_SET_NAME';
const LOAD_SAMPLER = 'LOAD_SAMPLER';
// const SAVE_SAMPLER = 'SAVE_SAMPLER';

export const changeCurrentSamplerName = input => ({
  type: CHANGE_CURRENT_SET_NAME,
  input,
});

export const setNames = names => ({
  type: SET_NAMES,
  names,
});

export const setTime = (playerId, buttonId, time) => ({
  type: SET_TIME,
  playerId,
  buttonId,
  time,
});

export const setRate = (playerId, rateId, rate) => ({
  type: SET_RATE,
  playerId,
  rateId,
  rate,
});

export const setVideo = (playerId, input) => ({
  type: SET_VIDEO,
  playerId,
  input,
});
export const loadSampler = sampler => ({
  type: LOAD_SAMPLER,
  samplerName: sampler.samplerName,
  p1input: sampler['1'].inputValue,
  p1time1: sampler['1']['1'],
  p1time2: sampler['1']['2'],
  p1time3: sampler['1']['3'],
  p1time4: sampler['1']['4'],
  p1rate1: sampler['1']['rate1'],
  p1rate2: sampler['1']['rate2'],
  p1rate3: sampler['1']['rate3'],
  p1rate4: sampler['1']['rate4'],

  p2input: sampler['2'].inputValue,
  p2time1: sampler['2']['1'],
  p2time2: sampler['2']['2'],
  p2time3: sampler['2']['3'],
  p2time4: sampler['2']['4'],
  p2rate1: sampler['2']['rate1'],
  p2rate2: sampler['2']['rate2'],
  p2rate3: sampler['2']['rate3'],
  p2rate4: sampler['2']['rate4'],

  p3input: sampler['3'].inputValue,
  p3time1: sampler['3']['1'],
  p3time2: sampler['3']['2'],
  p3time3: sampler['3']['3'],
  p3time4: sampler['3']['4'],
  p3rate1: sampler['3']['rate1'],
  p3rate2: sampler['3']['rate2'],
  p3rate3: sampler['3']['rate3'],
  p3rate4: sampler['3']['rate4'],

  p4input: sampler['4'].inputValue,
  p4time1: sampler['4']['1'],
  p4time2: sampler['4']['2'],
  p4time3: sampler['4']['3'],
  p4time4: sampler['4']['4'],
  p4rate1: sampler['4']['rate1'],
  p4rate2: sampler['4']['rate2'],
  p4rate3: sampler['4']['rate3'],
  p4rate4: sampler['4']['rate4'],
});
//THUNKS

export const fetchSampler = samplerName => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    const userId = getState().firebase.auth.uid;

    if (samplerName) {
      firestore
        .collection('users')
        .doc(userId)
        .collection('samplers')
        .doc(samplerName)
        .get()
        .then(sampler => {
          dispatch(loadSampler(sampler.data().playerState));
        });
    }
  };
};
export const saveSampler = playerState => (
  dispatch,
  getState,
  { getFirebase, getFirestore }
) => {
  const firestore = getFirestore();
  const userId = getState().firebase.auth.uid;

  const data = {
    '1': playerState['1'],
    '2': playerState['2'],
    '3': playerState['3'],
    '4': playerState['4'],
    samplerName: playerState['currentSamplerName'],
  };

  if (data.samplerName) {
    firestore
      .collection('users')
      .doc(userId)
      .collection('samplers')
      .doc(playerState.currentSamplerName)
      .set({ playerState: data });
  }
};
export const deleteSampler = samplerName => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    const userId = getState().firebase.auth.uid;

    if (samplerName !== '') {
      firestore
        .collection('users')
        .doc(userId)
        .collection('samplers')
        .doc(samplerName)
        .delete();
    }
  };
};

export const fetchNames = () => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();
    const userId = getState().firebase.auth.uid;

    firestore
      .collection('users')
      .doc(userId)
      .collection('samplers')
      .get()
      .then(samplers => {
        const samplerNames = [];
        samplers.docs.forEach(sampler => {
          samplerNames.push(sampler.id);
        });
        dispatch(setNames(samplerNames));
      });
  };
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_NAMES:
      return Object.assign({}, state, { samplerNames: action.names });
    case CHANGE_CURRENT_SET_NAME:
      return Object.assign({}, state, { currentSamplerName: action.input });
    case SET_TIME:
      return Object.assign({}, state, {
        [action.playerId]: {
          ...state[action.playerId],
          [action.buttonId]: action.time,
        },
      });
    case SET_RATE:
      return Object.assign({}, state, {
        [action.playerId]: {
          ...state[action.playerId],
          [action.rateId]: action.rate,
        },
      });
    case SET_VIDEO:
      return Object.assign({}, state, {
        [action.playerId]: {
          ...state[action.playerId],
          inputValue: action.input,
        },
      });
    case LOAD_SAMPLER:
      return Object.assign({}, state, {
        currentSamplerName: action.samplerName,
        1: {
          ...state['1'],
          inputValue: action.p1input,
          1: action.p1time1,
          2: action.p1time2,
          3: action.p1time3,
          4: action.p1time4,
          rate1: action.p1rate1,
          rate2: action.p1rate2,
          rate3: action.p1rate3,
          rate4: action.p1rate4,
        },
        2: {
          ...state['2'],
          inputValue: action.p2input,
          1: action.p2time1,
          2: action.p2time2,
          3: action.p2time3,
          4: action.p2time4,
          rate1: action.p2rate1,
          rate2: action.p2rate2,
          rate3: action.p2rate3,
          rate4: action.p2rate4,
        },
        3: {
          ...state['3'],
          inputValue: action.p3input,
          1: action.p3time1,
          2: action.p3time2,
          3: action.p3time3,
          4: action.p3time4,
          rate1: action.p3rate1,
          rate2: action.p3rate2,
          rate3: action.p3rate3,
          rate4: action.p3rate4,
        },
        4: {
          ...state['4'],
          inputValue: action.p4input,
          1: action.p4time1,
          2: action.p4time2,
          3: action.p4time3,
          4: action.p4time4,
          rate1: action.p4rate1,
          rate2: action.p4rate2,
          rate3: action.p4rate3,
          rate4: action.p4rate4,
        },
      });
    default:
      return state;
  }
}
