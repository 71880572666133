import { firebaseSecrets } from '../secrets';
import firebase from 'firebase/app';
import 'firebase/firestore';
// require('firebase/firestore');
import 'firebase/auth';

firebase.initializeApp(firebaseSecrets);
firebase.firestore();

export default firebase;
