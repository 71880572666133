import React, { Component } from 'react';
import Player from './Player';

const mainBody = {
  backgroundColor: 'black',
  color: 'white',
};

const performancePlayers = {
  paddingTop: '200px',
  paddingBottom: '200px',
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: 'center',
};
const videos = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: 'center',
};

export default class PerformView extends Component {
  render() {
    return (
      <div style={mainBody}>
        <p>Zoom in and scroll down to fill the screen :)</p>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>

        <br></br>

        <div style={performancePlayers}>
          <div style={videos}>
            <Player id={1} />
            <Player id={2} />
          </div>
          <div style={videos}>
            <Player id={3} />
            <Player id={4} />
          </div>
        </div>
      </div>
    );
  }
}
