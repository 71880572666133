import React, { Component } from 'react';
import { connect } from 'react-redux';
import history from '../history';
import { fetchNames } from '../store/reducers/samplerReducer';

class LoadingPage extends Component {
  componentDidMount() {
    // if (Object.keys(this.props.players.samplerNames).length === 0) {
    // if (Object.keys(this.props.players.samplerNames).length === 0) {
    //   this.props.getNames();
    // }
    history.push('/sampler_editor');
  }
  render() {
    return (
      <div>
        <h1>Loading Your Samplers!</h1>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    players: state.player,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getNames: () => dispatch(fetchNames()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(LoadingPage);
