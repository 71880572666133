const initialState = {
  samplerNames: [],
  currentSamplerName: '',
  1: {
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    rate1: 1,
    rate2: 1,
    rate3: 1,
    rate4: 1,
    setButtonOne: 'P1setButton1',
    setButtonTwo: 'P1setButton2',
    setButtonThree: 'P1setButton3',
    setButtonFour: 'P1setButton4',
    keyOne: 'q',
    keyTwo: 'w',
    keyThree: 'e',
    keyFour: 'r',
    inputValue: '',
  },
  2: {
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    rate1: 1,
    rate2: 1,
    rate3: 1,
    rate4: 1,
    setButtonOne: 'P2setButton1',
    setButtonTwo: 'P2setButton2',
    setButtonThree: 'P2setButton3',
    setButtonFour: 'P2setButton4',
    keyOne: 't',
    keyTwo: 'y',
    keyThree: 'u',
    keyFour: 'i',
    inputValue: '',
  },
  3: {
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    rate1: 1,
    rate2: 1,
    rate3: 1,
    rate4: 1,
    setButtonOne: 'P3setButton1',
    setButtonTwo: 'P3setButton2',
    setButtonThree: 'P3setButton3',
    setButtonFour: 'P3setButton4',
    keyOne: 'a',
    keyTwo: 's',
    keyThree: 'd',
    keyFour: 'f',
    inputValue: '',
  },
  4: {
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    rate1: 1,
    rate2: 1,
    rate3: 1,
    rate4: 1,
    setButtonOne: 'P4setButton1',
    setButtonTwo: 'P4setButton2',
    setButtonThree: 'P4setButton3',
    setButtonFour: 'P4setButton4',
    keyOne: 'a',
    keyTwo: 's',
    keyThree: 'd',
    keyFour: 'f',
    inputValue: '',
  },
};
module.exports = initialState;
