import React, { Component } from 'react';
import { connect } from 'react-redux';
import { default as MemberSignUp } from './MemberSignUp';
import { default as MemberLogin } from './MemberLogin';

const signUpBoxes = {
  display: 'flex',
  flexDirection: 'row',
  backgroundColor: 'azure',
};
const colorBlock = {
  height: '1140px',

  backgroundColor: 'azure',
};
const smallColorBlock = {
  height: '100px',

  backgroundColor: 'azure',
};

class LoginSignup extends Component {
  render() {
    return (
      <div>
        <div style={smallColorBlock}>
          <br></br>
          <h1 className="title">The Sandbox Sampler</h1>
        </div>

        <div className="columns" style={signUpBoxes}>
          <div className="column">
            <MemberLogin />
          </div>
          <div className="column">
            <MemberSignUp />
          </div>
          <div style={colorBlock}></div>
        </div>
      </div>
    );
  }
}
export default connect()(LoginSignup);
