import React, { Component } from 'react';
import { connect } from 'react-redux';
import { signUp } from '../../store/reducers/authReducer';

const userInputs = {
  // flexDirection: 'row',
};
const userInput = {
  padding: '5px',
  width: '50%',
  margin: 'auto',
  justifyContent: 'center',
};
const userSignup = {
  height: '446px',
  backgroundColor: 'floralwhite',
};

class MemberSignUp extends Component {
  state = {
    email: '',
    password: '',
    name: '',
  };

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handleSubmit = e => {
    e.preventDefault();
    this.props.signUp(this.state);
  };
  render() {
    const { authError } = this.props;
    return (
      <div className="box" style={userSignup}>
        <form onSubmit={this.handleSubmit}>
          <h3 className={'prayerReqTitle title is-3'}>Member Sign Up</h3>
          <div style={userInputs}>
            <div style={userInput}>
              <label htmlFor="email">Email</label>
              <input
                className="input"
                type="email"
                name="email"
                onChange={this.handleChange}
                placeholder="email..."
              />
            </div>

            <div style={userInput}>
              <label htmlFor="name">Name</label>
              <input
                className="input"
                type="text"
                name="name"
                onChange={this.handleChange}
                placeholder="Name..."
              />
            </div>
            <div style={userInput}>
              <label htmlFor="password">Password</label>
              <input
                className="input"
                type="password"
                name="password"
                onChange={this.handleChange}
                placeholder="password..."
              />
            </div>
          </div>

          <div className="input-field">
            <button className="button">Sign Up</button>
          </div>
        </form>
        <div>{authError ? <p>{authError}</p> : null}</div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    authError: state.auth.authError,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    signUp: newUser => dispatch(signUp(newUser)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MemberSignUp);
