import React from 'react';
import { Link } from 'react-router-dom';

const SignedOutLinks = () => (
  <div>
    <nav className="navbar" role="navigation" aria-label="main navigation">
      <div className="navbar-brand">
        <Link to="/login" className="navbar-item title is-4">
          Login
        </Link>
        <Link to="/info" className="navbar-item title is-4">
          Info
        </Link>
      </div>
    </nav>
  </div>
);
export default SignedOutLinks;
