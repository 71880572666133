import history from '../../history';
import { persistor } from '../index';

//ACTION TYPES--------------------------
const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
const LOGIN_ERROR = 'LOGIN_ERROR';

const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
// const LOGOUT_ERROR = 'LOGOUT_ERROR';

const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
const SIGNUP_ERROR = 'SIGNUP_ERROR';

//ACTION CREATORS-----------------------
const loginGroupMemeber = () => ({ type: LOGIN_SUCCESS });
const loginError = err => ({ type: LOGIN_ERROR, err });

const logout = () => ({ type: LOGOUT_SUCCESS });

const signUpSuccess = () => ({ type: SIGNUP_SUCCESS });
const signUpError = err => ({ type: SIGNUP_ERROR, err });

//THUNK CREATORS------------------------
export const signIn = credentials => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();

    firebase
      .auth()
      .signInWithEmailAndPassword(credentials.email, credentials.password)
      .then(() => {
        dispatch(loginGroupMemeber());
        history.push('/loadingpage');
      })
      .catch(err => {
        dispatch(loginError(err));
      });
  };
};
export const signOut = () => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    persistor.purge();
    const firebase = getFirebase();
    const firestore = getFirestore();
    const userUnsuscribe = firestore.collection('users').onSnapshot(() => {});
    userUnsuscribe();

    firebase
      .auth()
      .signOut()
      .then(() => {
        dispatch(logout());
        history.push('/login');
        window.location.reload();
      });
  };
};
export const signUp = newUser => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();

    firebase
      .auth()
      .createUserWithEmailAndPassword(newUser.email, newUser.password)
      .then(resp => {
        return firestore
          .collection('users')
          .doc(resp.user.uid)
          .set({
            name: newUser.name,
            email: newUser.email,
          });
      })
      .then(() => {
        dispatch(signUpSuccess());
        history.push('/sampler_editor');
      })
      .catch(err => {
        dispatch(signUpError(err));
      });
  };
};
export const resetPass = email => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    firebase.auth().sendPasswordResetEmail(email);
  };
};
//INITIAL STATE-------------------------
const initState = {
  authError: null,
};

//REDUCER-------------------------------
const authReducer = (state = initState, action) => {
  switch (action.type) {
    case LOGIN_ERROR:
      console.log('login failed');
      return {
        ...state,
        authError: 'Login Failed',
      };
    case LOGIN_SUCCESS:
      console.log('login success');
      return {
        ...state,
        authError: null,
      };
    case LOGOUT_SUCCESS:
      console.log('logout success');
      return {};
    case SIGNUP_SUCCESS:
      console.log('signup success');
      return {
        ...state,
        authError: null,
      };
    case SIGNUP_ERROR:
      console.log('signup error ' + action.err.message);
      return {
        ...state,
        authError: action.err.message,
      };
    default:
      return state;
  }
};
export default authReducer;
