process.env.FIREBASE_apiKey = 'AIzaSyDW5WQq6hNedJOyi0FL-tn2RZ0hZG1OX6Y';
process.env.FIREBASE_authDomain = 'home-group-app.firebaseapp.com';
process.env.FIREBASE_databaseURL = 'https://home-group-app.firebaseio.com';
process.env.FIREBASE_projectId = 'home-group-app';
process.env.FIREBASE_storageBucket = 'home-group-app.appspot.com';
process.env.FIREBASE_messagingSenderId = '200998020458';

const firebaseSecrets = {
  apiKey: 'AIzaSyCncRHgwoENHE62m6Hx1Cnmf6MMX0VMMTs',
  authDomain: 'sandbox-sampler.firebaseapp.com',
  databaseURL: 'https://sandbox-sampler.firebaseio.com',
  projectId: 'sandbox-sampler',
  storageBucket: 'sandbox-sampler.appspot.com',
  messagingSenderId: '11323937492',
  appId: '1:11323937492:web:01802834aab041bb',
};

module.exports = {
  firebaseSecrets,
};
