const initialState = {
  1: {
    setButtonOne: 'P1setButton1',
    setButtonTwo: 'P1setButton2',
    setButtonThree: 'P1setButton3',
    setButtonFour: 'P1setButton4',
    keyOne: 'q',
    keyTwo: 'w',
    keyThree: 'e',
    keyFour: 'r',
    keyFive: 'x',
    setRateOne: 'P1setRate1',
    setRateTwo: 'P1setRate2',
    setRateThree: 'P1setRate3',
    setRateFour: 'P1setRate4',
  },
  2: {
    setButtonOne: 'P2setButton1',
    setButtonTwo: 'P2setButton2',
    setButtonThree: 'P2setButton3',
    setButtonFour: 'P2setButton4',
    keyOne: 't',
    keyTwo: 'y',
    keyThree: 'u',
    keyFour: 'i',
    keyFive: 'c',
    setRateOne: 'P2setRate1',
    setRateTwo: 'P2setRate2',
    setRateThree: 'P2setRate3',
    setRateFour: 'P2setRate4',
  },
  3: {
    setButtonOne: 'P3setButton1',
    setButtonTwo: 'P3setButton2',
    setButtonThree: 'P3setButton3',
    setButtonFour: 'P3setButton4',
    keyOne: 'a',
    keyTwo: 's',
    keyThree: 'd',
    keyFour: 'f',
    keyFive: 'b',
    setRateOne: 'P3setRate1',
    setRateTwo: 'P3setRate2',
    setRateThree: 'P3setRate3',
    setRateFour: 'P3setRate4',
  },
  4: {
    setButtonOne: 'P4setButton1',
    setButtonTwo: 'P4setButton2',
    setButtonThree: 'P4setButton3',
    setButtonFour: 'P4setButton4',
    keyOne: 'g',
    keyTwo: 'h',
    keyThree: 'j',
    keyFour: 'k',
    keyFive: 'n',
    setRateOne: 'P4setRate1',
    setRateTwo: 'P4setRate2',
    setRateThree: 'P4setRate3',
    setRateFour: 'P4setRate4',
  },
};

export default function(state = initialState, action) {
  switch (action.type) {
    default:
      return state;
  }
}
