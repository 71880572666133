import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Route, Switch } from 'react-router-dom';
import {
  app_info,
  SamplerEditor,
  PerformView,
  LoginSignUp,
  LoadingPage,
} from './components';

class Routes extends Component {
  render() {
    const { auth } = this.props;

    return (
      <Switch>
        <Route exact path="/info" component={app_info} />
        <Route exact path="/login" component={LoginSignUp} />
        {auth.uid && (
          <Switch>
            <Route exact path="/sampler_editor" component={SamplerEditor} />
            <Route exact path="/perform" component={PerformView} />
            <Route exact path="/loadingpage" component={LoadingPage} />
          </Switch>
        )}
        {/* Displays our Login component as a fallback */}
        <Route component={LoginSignUp} />
      </Switch>
    );
  }
}

const mapStateToProps = state => {
  return {
    // Being 'logged in' for our purposes will be defined has having a state.user that has a truthy id.
    // Otherwise, state.user will be an empty object, and state.user.id will be falsey
    // isLoggedIn: !!state.user.id,
    auth: state.firebase.auth,
  };
};

export default withRouter(connect(mapStateToProps)(Routes));
