// import createHistory from 'history/createBrowserHistory';
// import createMemoryHistory from 'history/createMemoryHistory';

// import { createBrowserHistory } from 'history';

// const history =
//   process.env.NODE_ENV === 'test' ? createMemoryHistory() : createHistory();

// export default history;

import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

export default history;
